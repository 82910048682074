import React, { useState, useRef } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"

const inputStyle = "block mb-4 p-1 border border-gray-300 w-full h-10"
const errorStyle = "text-red-400 border-l-4 border-red-400 bg-red-100 p-2"

export default function FormComponent({ area, municipality }) {
  const [state, setState] = useState("input")
  const [personalDescCounter, setPersonalDescCounter] = useState(0)
  const [campaignDescCounter, setCampaignDescCounter] = useState(0)

  var errorFocusId = "none"
  var imageId = useRef(null)

  const counterStyle = counter => {
    var baseStyle = "text-xs text-gray-500 m-2"
    if (counter > 200) {
      baseStyle += " text-red-800"
    }

    return baseStyle
  }

  const encodeImage = async (image, callback) => {
    const blobURL = URL.createObjectURL(image)
    const img = new Image()
    img.src = blobURL
    img.onload = function () {
      URL.revokeObjectURL(this.src)
      const [newWidth, newHeight] = calculateSize(img, 1920, 1080)
      const canvas = document.createElement("canvas")
      canvas.width = newWidth
      canvas.height = newHeight
      const ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0, newWidth, newHeight)
      canvas.toBlob(
        blob => {
          uploadImage(blob)
        },
        "image/jpeg",
        0.9
      )
    }
  }

  const calculateSize = (img, maxWidth, maxHeight) => {
    let width = img.width
    let height = img.height

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width)
        width = maxWidth
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height)
        height = maxHeight
      }
    }
    return [width, height]
  }

  const uploadImage = async image => {
    if (imageId.current !== null) deleteUpload(imageId.current)
    var formData = new FormData()
    formData.append("image", image)
    imageId.current = fetch(
      "https://test-vercel-sepia-one.vercel.app/api/upload",
      {
        method: "POST",
        body: formData,
        mode: "cors",
      }
    ).then(r => {
      if (r.status.toString()[0] === "5") setState("error")
      if (r.status === 200) return r
    })
  }

  const deleteUpload = async deleteImageId => {
    const imageToDelete = await imageId.current.then(async image_Id => {
      const data = await image_Id.json()
      return String(JSON.parse(data).id)
    })
    fetch("/.netlify/functions/delete-image", {
      method: "POST",
      body: imageToDelete,
    })
  }

  const submit = async data => {
    setState("loading")
    data.image_id = await imageId.current.then(async image_Id => {
      const data = await image_Id.json()

      return String(JSON.parse(data).id)
    })
    const dataJSON = JSON.stringify(data)
    fetch("/.netlify/functions/submit-data", {
      method: "POST",
      body: dataJSON,
    }).then(response => {
      if (response.status === 200) setState("done")
      if (response.status === 500) setState("error")
    })
  }

  const GenerateSelection = arrayElements => {
    return arrayElements.map((el, index) => (
      <option key={index} value={el}>
        {el}
      </option>
    ))
  }

  const Focus = formik => {
    formik.isSubmitting.validateForm().then(() => {
      if (errorFocusId !== "none") {
        document.getElementById(errorFocusId).focus()
      }
    })
  }

  const UploadButton = formik => {
    if (state === "loading") {
      return (
        <div>
          <img
            className="w-20 ml-4"
            alt="loading animation"
            src="./loading-animation-2.gif"
          />
        </div>
      )
    }
    return (
      <button
        type="submit"
        className="bg-primary text-white py-2 px-4 m-4 hover:bg-secondary hover:text-primary border-2 border-primary hover:shadow-2xl"
        onClick={() => Focus(formik)}
      >
        Lähetä
      </button>
    )
  }

  if (state === "done") {
    return <div className="p-10">Lomake lähetettiin onnistuneesti</div>
  }

  if (state === "error") {
    return <div className="p-10">Error</div>
  }

  return (
    <div className="p-10 over">
      <Title />
      <Formik
        initialValues={{
          name: "",
          surname: "",
          // candidateNumber: "",
          age: "",
          picture: "",
          area: "Etelä-Karjalan hyvinvointialue",
          municipality: "Akaa",
          personalDescription: "",
          campaignDescription: "",
          email: "",
          blog: "",
          facebook: "",
          instagram: "",
        }}
        validate={values => {
          const errors = {}

          if (values.campaignDescription.replace(" ", "").length > 200) {
            errors.campaignDescription = "Too long, max 200"
            errorFocusId = "campaignDescription"
          }

          if (values.personalDescription.replace(" ", "").length > 200) {
            errors.personalDescription = "Too long, max 200"
            errorFocusId = "personalDescription"
          }

          if (!values.picture) {
            errors.picture = "Vaadittu"
            errorFocusId = "picture"
          }

          if (!values.age) {
            errors.age = "Vaadittu"
            errorFocusId = "age"
          }

          if (typeof values.age !== "number") {
            errors.age = "field is not a number"
            errorFocusId = "age"
          }

          if (values.age < 0) {
            errors.age = "Age can't be smaller than 0"
            errorFocusId = "age"
          }
          // if (!values.candidateNumber) {
          //   errors.candidateNumber = "Vaadittu"
          //   errorFocusId = "candidateNumber"
          // }

          // if (typeof values.candidateNumber !== "number") {
          //   errors.candidateNumber = "field is not a number"
          //   errorFocusId = "candidateNumber"
          // }

          // if (values.candidateNumber < 0) {
          //   errors.candidateNumber = "Candidate number can't be smaller than 0"
          //   errorFocusId = "candidateNumber"
          // }

          if (!values.surname) {
            errors.surname = "Vaadittu"
            errorFocusId = "surname"
          }

          if (!values.name) {
            errors.name = "Vaadittu"
            errorFocusId = "name"
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          submit(values)
        }}
      >
        {formik => (
          <Form>
            <Label title="Etunimi" required={true}>
              <Field
                id="name"
                type="text"
                name="name"
                className={inputStyle}
                placeholder="esim. Maija"
              />
              <ErrorMessage
                name="name"
                component="div"
                className={errorStyle}
              />
            </Label>
            <Label title="Sukunimi" required={true}>
              <Field
                id="surname"
                type="text"
                name="surname"
                className={inputStyle}
                placeholder="esim. Suomalainen"
              />
              <ErrorMessage
                name="surname"
                component="div"
                className={errorStyle}
              />
            </Label>
            <Label title="Ehdokasnumero" required={true}>
              <Field
                id="candidateNumber"
                type="number"
                name="candidateNumber"
                className={inputStyle}
                placeholder="241"
              />
              <ErrorMessage name="candidateNumber" component="div" className={errorStyle} />
            </Label>
            <Label title="Ikä" required={true}>
              <Field
                id="age"
                type="number"
                name="age"
                className={inputStyle}
                placeholder="37"
              />
              <ErrorMessage name="age" component="div" className={errorStyle} />
            </Label>

            <Label
              title="Kuva"
              required={true}
              description="Tuetut muodot ovat jpeg ja png."
            >
              <input
                id="picture"
                name="picture"
                type="file"
                onChange={event => {
                  formik.setFieldValue("picture", event.target.files[0])
                  encodeImage(event.target.files[0], uploadImage)
                }}
              />
              <ErrorMessage
                name="picture"
                component="div"
                className={errorStyle}
              />
            </Label>

            <Label title="Hyvinvointialue" required={true}>
              {area.length === 0 ? (
                <div>
                  <img
                    className="w-20 ml-4"
                    alt="loading animation"
                    src="./loading-animation-2.gif"
                  />
                </div>
              ) : (
                <div>
                  <Field as="select" name="area" className={inputStyle}>
                    {GenerateSelection(area)}
                  </Field>
                </div>
              )}
            </Label>

            <Label title="Kotikunta" required={true}>
              {municipality.length === 0 ? (
                <div>
                  <img
                    className="w-20 ml-4"
                    alt="loading animation"
                    src="./loading-animation-2.gif"
                  />
                </div>
              ) : (
                <Field as="select" name="municipality" className={inputStyle}>
                  {GenerateSelection(municipality)}
                </Field>
              )}
            </Label>

            <Label
              title="Esittely"
              required={false}
              description="max. 200 merkkiä"
            >
              <textarea
                id="personalDescription"
                className="block w-full border border-gray-300 p-2"
                name="personalDescription"
                onChange={event => {
                  formik.setFieldValue(
                    "personalDescription",
                    event.target.value
                  )
                  setPersonalDescCounter(
                    event.target.value.replace(" ", "").length
                  )
                }}
              />
              <div className={counterStyle(personalDescCounter)}>
                {personalDescCounter}/200
              </div>
              <ErrorMessage
                name="personalDescription"
                component="div"
                className={errorStyle}
              />
            </Label>

            <Label
              title="Kampanjateemat"
              required={false}
              description="max. 200 merkkiä"
            >
              <textarea
                id="campaignDescription"
                className="block w-full border border-gray-300 p-2"
                name="campaingDescription"
                onChange={event => {
                  formik.setFieldValue(
                    "campaignDescription",
                    event.target.value
                  )
                  setCampaignDescCounter(
                    event.target.value.replace(" ", "").length
                  )
                }}
              />
              <div className={counterStyle(campaignDescCounter)}>
                {campaignDescCounter}/200
              </div>
              <ErrorMessage
                name="campaignDescription"
                component="div"
                className={errorStyle}
              />
            </Label>

            <Label title="Sähköposti" required={false}>
              <Field
                type="text"
                name="email"
                placeholder="esim. matti.meikalainen@liikenyt.fi"
                className={inputStyle}
              />
            </Label>

            <Label title="Blogi tai sivusto" required={false}>
              <Field
                type="text"
                name="blog"
                placeholder="esim. https://liikenyt.fi"
                className={inputStyle}
              />
            </Label>

            <Label
              title="Facebook"
              required={false}
              description="Jos sinulla on julkinen Facebook-profiili tai -sivu, lisää sen osoite tähän."
            >
              <Field
                type="text"
                name="facebook"
                placeholder="esim. https://www.facebook.com/liikenyt"
                className={inputStyle}
              />
            </Label>

            <Label
              title="Instagram"
              required={false}
              description="Jos sinulla on julkinen Instagram-tili, lisää sen osoite tähän."
            >
              <Field
                type="text"
                name="instagram"
                placeholder="esim. https://www.instagram.com/liikenyt"
                className={inputStyle}
              />
            </Label>

            <UploadButton isSubmitting={formik} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

function Label({ title, description, required, children }) {
  return (
    <div className="m-4 mb-8  border-b-2">
      <label className="block mb-4">
        {title}
        {required && <p className="inline text-red-500"> *</p>}
        <p className="my-4">{description}</p>
        {children}
      </label>
    </div>
  )
}

const Title = () => {
  return (
    <h2 className="md:text-3xl text-xl font-bold border-b-2 pb-4 ">
      Suojattu: Aluevaaliehdokkaat, tiedonkeruulomake
    </h2>
  )
}
